import * as React from "react";
import { Layout, PageTitle, Access } from "../components";
import Seo from "../components/Seo";

const AccessPage = () => {
  return (
    <Layout>
      <Seo title="ACCESS - アクセス" />
      <PageTitle title="ACCESS" subTitle="アクセス" />
      <Access />
    </Layout>
  );
};

export default AccessPage;
